<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <MobileBannerCarousel></MobileBannerCarousel>
    <div class="d-flex justify-content-between align-items-center mx10">
      <h4 class="m-t-15"></h4>
      <div class="d-flex m-t-15 mobile-filter-buttons">
        <button 
          class="primary-btn d-flex align-items-center mx5"
          @click="openFilterModal"
        >
          {{ $t('REPORT_SALES.SEARCH') }}
          <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
        </button>
        <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
        <FilterTourism></FilterTourism>
      </div>
    </div>
    <MobileFilterModal :isFilterModalVisible.sync="isFilterModalVisible"></MobileFilterModal>
    <section>
      <div class="df-c m-t-20">
        <h3>Flights form Tel-Aviv to Berlin</h3>
      </div>
      <FlightDesItem class="p10">
        <button 
          class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
          slot="actionPart" 
          @click="openMobileDetailModal()"
        >
          <img src="/assets/img/icons/info.svg" alt="" class="">
          {{ $t( "TOURISM.FULL_DETAILS" ) }}
        </button>
      </FlightDesItem>
      <FlightDesItem class="p10">
        <button 
          class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
          slot="actionPart" 
          @click="openMobileDetailModal()"
        >
          <img src="/assets/img/icons/info.svg" alt="" class="">
          {{ $t( "TOURISM.FULL_DETAILS" ) }}
        </button>
      </FlightDesItem>
      <FlightDesItem class="p10">
        <button 
          class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
          slot="actionPart" 
          @click="openMobileDetailModal()"
        >
          <img src="/assets/img/icons/info.svg" alt="" class="">
          {{ $t( "TOURISM.FULL_DETAILS" ) }}
        </button>
      </FlightDesItem>
      <div class="df-c m-t-10 m-b-20">
        <button class="primary-btn primary-btn_sm">
          {{ $t( "TOURISM.SHOW_MORE" ) }}
        </button>
      </div>
    </section>
    <MobileDetailModal :isOrder="false"></MobileDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../../components/BannerSection";
import FilterTourism from "../../../../components/tourism/FilterTourism";
import SortTourism from "../../../../components/tourism/SortTourism";
import FlightDesItem from "../../../../components/tourism/flight/FlightDesItem";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import MobileFilterModal from "../../../../components/tourism/flight/MobileFilterModal.vue";
import MobileBannerCarousel from "../../../../components/tourism/MobileBannerCarousel.vue";

export default {
  name: "MobileFlightDestinationDates",
  components: {
    BannerSection,
    FilterTourism,
    SortTourism,
    FlightDesItem,
    MobileDetailModal,
    MobileBannerCarousel,
    MobileFilterModal
  },
  data() {
    return {
      isFilterModalVisible: false
    }
  },
  methods: {
    sortDeals() {
    },
    openMobileDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    },
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";
.mobile-filter-buttons {
  .toursim-sort-filter_btn {
    border-radius: 5px;
  }
  .toursim-sort-filter_btn {
    border-radius: 5px;
  }
}
</style>